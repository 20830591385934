import { useMutation } from 'react-query';
import { apiClient } from '@components/api/apiClient';
import paths from '@components/api/paths';
import { getAuthData } from '@utils/storage';
import { IRequestMeta } from 'constants/types';

interface IMultipleProfilesData extends IRequestMeta {
  data: any;
  success: boolean;
}

async function getBusinessProfile(userRealmId: string) {
  try {
    const { realmIdentifier, systemUserId, userId } = getAuthData();

    if (realmIdentifier && systemUserId) {
      const url = paths.pgFetchMultipleProfile(
        realmIdentifier || '',
        userId || 0
      );
      const response: IMultipleProfilesData = await apiClient.post(url, {
        input: { ids: [userRealmId] },
      });
      return response;
    }
  } catch (error: any) {
    return Promise.reject(error);
  }
}

const useBusinessData = () => {
  return useMutation({
    mutationFn: getBusinessProfile,
  });
};

export { useBusinessData };
