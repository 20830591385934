import { NCLoader } from '@ninjacart/nc-components-ui';

function Loader() {
  return (
    <NCLoader
      fontWeight
      borderWidth="4"
      classes=" bg-[#000000b2] "
      color="#1FAB24"
      label="Loading..."
      labelColor="#ffffff"
      labelMargin="1"
      loaderContentBgColor="transperant"
      logoColor="#20AB24"
      size="sm"
      variantType="ninja"
    />
  );
}

export default Loader;
