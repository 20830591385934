import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import useButtonTheme, { ButtonThemeProps } from './buttonTheme';

interface ButtonProps
  extends ButtonThemeProps,
    Omit<
      DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      'color'
    > {
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  label: string;
}

function Button(props: ButtonProps) {
  const { leftComponent, rightComponent, label, ...otherProps } = props;

  const customClasses = useButtonTheme(otherProps);

  return (
    <button {...otherProps} className={customClasses}>
      {leftComponent || null}
      {label}
      {rightComponent || null}
    </button>
  );
}

export default Button;
