/* eslint-disable */
import * as React from 'react';
import Image from 'next/image';

interface IProps {
  showModal: boolean;
  title: string;
  description: string;
  buttons?: any;
  variant?: string;
  buttonType?: string;
  icon: any;
}

function Popup(props: IProps) {
  const {
    showModal,
    title,
    description,
    buttons,
    variant = 'v2',
    buttonType = 'filled',
    icon,
  } = props;
  let headerClassName = 'flex items-start justify-between';
  if (variant === 'v2') {
    headerClassName = '';
  }

  let bodyClassName = 'font-light leading-5 text-left text-sm';
  if (variant === 'v2') {
    bodyClassName = 'font-light leading-5 text-center text-base';
  }

  let footerClassName = 'flex items-center justify-end';
  if (variant === 'v2') {
    footerClassName = 'flex items-center justify-evenly';
  }

  let buttonClassName =
    'text-[#5FA744] background-transparent focus:outline-none px-3 py-1 mb-1 mr-1 font-bold transition-all duration-150 ease-linear outline-none border rounded-lg border-primary tw-primary tw-whitespace-nowrap tw-w-auto';
  if (buttonType === 'filled') {
    buttonClassName =
      '!tw-bg-primary-500 tw-rounded-lg tw-w-[80%] tw-h-[40px] text-white focus:outline-none px-3 py-1 mb-1 mr-1  transition-all duration-150 ease-linear outline-none tw-whitespace-nowrap tw-w-auto';
  }

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
            <div className="desktop:w-4/12 tablet:w-3/6 relative w-5/6 mx-auto my-6">
              {/* content */}
              <div className="border-0 rounded-lg  relative flex flex-col bg-white outline-none  min-h-[200px] p-4">
                {/* header */}
                <div className={headerClassName}>
                  <h3 className="tw-mb-[20px] tw-text-center tw-text-xl tw-font-bold">
                    {title}
                  </h3>
                </div>
                {icon && (
                  <div className="tw-mb-[30px] tw-flex tw-items-center tw-justify-center">
                    <Image src={icon} alt="" width={100} height={100} />
                  </div>
                )}
                {/* body */}
                <div className="relative flex-auto py-4 tw-mb-[30px] tw-flex tw-items-center tw-justify-center">
                  <p className="tw-l-5 tw-text-center tw-text-[15px]">
                    {description}
                  </p>
                </div>
                {/* footer */}
                <div className={footerClassName}>
                  {buttons.map((item: any, key: number) => {
                    if (variant === 'v1') {
                      return (
                        <button
                          key={`${item.title}${key}`}
                          className="text-primary background-transparent focus:outline-none px-2 py-2 mb-1 mr-1 text-sm font-bold uppercase transition-all duration-150 ease-linear outline-none"
                          type="button"
                          onClick={item.callback}
                        >
                          {item.title}
                        </button>
                      );
                    }
                    if (variant === 'v2') {
                      return (
                        <button
                          key={`${item.title}${key}`}
                          className={buttonClassName}
                          type="button"
                          onClick={item.callback}
                        >
                          {item.title}
                        </button>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 backdrop-blur-sm  opacity-50" />
        </>
      ) : null}
    </>
  );
}

export default Popup;
