import { StaticImageData } from 'next/image';

export interface MenusType {
  icon: StaticImageData;
  menuName: string;
  name: string;
  route: string;
  trackedEventName?: string;
  key?: string;
}

export interface nudgesInterface {
  completeKyc: number;
  addContractDashboard: number;
  addParties: number;
  youPayReceive: number;
  addMoney: number;
  clickUpi: number;
}

export const permissions = {
  permissions: {
    requirement: {
      add_rfq_visibility: true,
      create: false,
      detail: true,
      show_interest: false,
      chats: false,
      call: false,
      filters: true,
      report: false,
    },
    chat: {
      search: true,
      chat_old_users: false,
      send_picture: false,
    },
    businesses: {
      manage_my_network: false,
      invitations: false,
      connect: false,
      chats: false,
      call: false,
      filters: true,
      profile: true,
    },
    profile_menu: {
      see_your_profile: true,
      my_requirements: false,
      interested_posts: false,
      profile_views: false,
      request_for_feature: true,
      see_more: true,
    },
    profile: {
      add_contract: false,
      connect: false,
      chats: false,
      call: false,
      edit_banner: true,
      edit_profile: true,
      report: false,
      reviews: false,
      write_review: false,
      requirements: false,
      add_rfq_visibility: true,
    },
    others: {
      search: true,
      notification: true,
    },
  },
};

export enum FEATURE_RELEASE_VERSION {
  NINJA_ONE = 162,
}
