import Popup from '@components/molecules/Popup';
import { getNativeUtils, redirectToPage } from '@utils/nativeUtils';
import { useGlobalContext } from 'contexts';
import completeProfileIcon from 'assets/dashIcons/profile-popup.svg';

function BusinessProfileDialog() {
  const { setShowProfilePopup, showProfilePopup } = useGlobalContext();

  const handleCompleteProfile = () => {
    if (getNativeUtils()) {
      setShowProfilePopup(false);
      redirectToPage('/commerce/', 'Commerce-Profile');
    }
  };

  return (
    <Popup
      buttons={[
        {
          title: 'Complete Business Profile',
          callback: handleCompleteProfile,
        },
      ]}
      description="Ninja Payment Assurance can be availed only after completing your business profile. It will only take 2 minutes."
      icon={completeProfileIcon}
      showModal={showProfilePopup}
      title="Complete your Business Profile"
    />
  );
}

export default BusinessProfileDialog;
