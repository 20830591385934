/* eslint-disable react/require-default-props */

import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

const HomeIcon = ({ height = 24, width = 24, color = 'black' }: IProps) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      height="24"
      id="mask0_49_2473"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="24"
      x="0"
      y="0"
    >
      <rect fill={color} height="24" width="24" />
    </mask>
    <g mask="url(#mask0_49_2473)">
      <path
        d="M5.99997 19H9.34615V13.9615C9.34615 13.7055 9.43277 13.4908 9.606 13.3176C9.77923 13.1443 9.9939 13.0577 10.25 13.0577H13.75C14.0061 13.0577 14.2207 13.1443 14.3939 13.3176C14.5672 13.4908 14.6538 13.7055 14.6538 13.9615V19H18V10.1539C18 10.1026 17.9888 10.0561 17.9663 10.0144C17.9439 9.97277 17.9134 9.93591 17.875 9.90386L12.1827 5.62501C12.1314 5.58015 12.0705 5.55771 12 5.55771C11.9295 5.55771 11.8686 5.58015 11.8173 5.62501L6.12497 9.90386C6.08652 9.93591 6.05607 9.97277 6.03362 10.0144C6.01119 10.0561 5.99997 10.1026 5.99997 10.1539V19ZM4.5 19V10.1539C4.5 9.86765 4.56402 9.5965 4.69207 9.34041C4.82012 9.08433 4.99712 8.87344 5.22307 8.70774L10.9154 4.41929C11.2313 4.17826 11.5923 4.05774 11.9984 4.05774C12.4046 4.05774 12.7666 4.17826 13.0846 4.41929L18.7769 8.70774C19.0028 8.87344 19.1798 9.08433 19.3079 9.34041C19.4359 9.5965 19.5 9.86765 19.5 10.1539V19C19.5 19.409 19.3522 19.7612 19.0567 20.0567C18.7612 20.3522 18.4089 20.5 18 20.5H14.0577C13.8016 20.5 13.5869 20.4134 13.4137 20.2401C13.2404 20.0669 13.1538 19.8522 13.1538 19.5961V14.5577H10.8461V19.5961C10.8461 19.8522 10.7595 20.0669 10.5863 20.2401C10.413 20.4134 10.1984 20.5 9.94227 20.5H5.99997C5.59101 20.5 5.23877 20.3522 4.94327 20.0567C4.64776 19.7612 4.5 19.409 4.5 19Z"
        fill={color}
      />
    </g>
  </svg>
);

export default HomeIcon;
