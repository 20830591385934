export type ButtonThemeProps = {
  variant?: Variant;
  color?: Color;
  size?: Size;
  isRounded?: boolean;
  disabled?: boolean;
  isTextFit?: boolean;
  className?: string;
};

type Variant = keyof typeof VARIANTS;
type Color = keyof typeof COLORS;
type Size = keyof typeof Sizes;

type VariantClasses = { [key in Variant]: string };

type VariantColorClasses = {
  [key in Variant]?: {
    [key in Color]?: string;
  };
};

enum Sizes {
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  full = 'full',
}

const VARIANTS = {
  primary: 'primary',
  secondary: 'secondary',
  outlined: 'outlined',
  primary2: 'primary2',
};

const COLORS = {
  primary: 'primary',
  danger: 'danger',
  warning: 'warning',
  primary2: 'primary2',
};

const SIZES = {
  lg: 'tw-w-48',
  md: 'tw-w-36',
  sm: 'tw-w-24',
  full: 'tw-w-full',
};

const VARIANT_CLASSES: VariantClasses = {
  primary: 'tw-text-white tw-font-bold tw-non-italic',
  secondary: 'tw-text-white tw-font-bold tw-non-italic',
  outlined: 'tw-font-bold',
  primary2: 'tw-text-white tw-font-bold tw-non-italic',
};

const DISABLED_CLASSES: VariantClasses = {
  primary: 'tw-bg-gray-400',
  secondary: 'tw-bg-gray-400',
  outlined: 'tw-bg-gray-400',
  primary2: 'tw-bg-gray-400',
};

const ENABLED_CLASSES: VariantClasses = {
  primary: 'tw-ring-offset-1 tw-ring-transparent active:tw-scale-90',
  secondary: 'tw-ring-offset-1 tw-ring-transparent active:tw-scale-90',
  outlined:
    'tw-bg-white tw-border tw-ring-transparent hover:tw-text-white active:tw-text-white active:tw-scale-90',
  primary2: 'tw-ring-offset-1 tw-ring-transparent active:tw-scale-90',
};

const COLOR_SPECIFIC_CLASSES: VariantColorClasses = {
  primary: {
    primary:
      'tw-bg-primary-500 hover:tw-bg-primary-500/90 focus:tw-ring-primary-500',
    danger: 'tw-bg-[#ff5656] hover:tw-bg-[#ff5656]/90 focus:tw-ring-[#ff5656]',
    warning: 'tw-bg-[#fca706] hover:tw-bg-[#fca706]/90 focus:tw-ring-[#fca706]',
    primary2:
      'tw-bg-primary-500 tw-text-white hover:tw-bg-primary2-bg focus:tw-ring-primary-500',
  },
  secondary: {
    primary: 'tw-bg-secondary',
    danger: 'tw-bg-secondary',
    warning: 'tw-bg-secondary',
    primary2: 'tw-bg-secondary',
  },
  outlined: {
    primary:
      'tw-text-primary-500 tw-border-primary-500 focus:tw-border-primary-500 hover:tw-bg-primary-500 active:tw-bg-primary-500',
    danger:
      'tw-text-[#ff5656] active:tw-bg-[#ff5656]/120 tw-border-[#ff5656] hover:tw-bg-[#ff5656] active:tw-bg-[#ff5656]/120',
    warning:
      'tw-text-[#fca706] active:tw-bg-[#fca706]/120 tw-border-[#fca706] hover:tw-bg-[#fca706] active:tw-bg-[#fca706]/120',
    primary2:
      'tw-text-primary-500 tw-text-white tw-border-primary-500 focus:tw-border-primary-500 hover:tw-bg-primary2-bg active:tw-bg-primary-500',
  },
};

function getUIClasses(props: ButtonThemeProps) {
  const { disabled, variant = 'primary', color = 'primary' } = props;

  const disabledClass = DISABLED_CLASSES[variant];
  const colorSpecificClasses = COLOR_SPECIFIC_CLASSES?.[variant]?.[color] || '';
  const enabledClasses = ENABLED_CLASSES?.[variant] || '';

  return disabled ? disabledClass : `${enabledClasses} ${colorSpecificClasses}`;
}

const useButtonTheme = (props: ButtonThemeProps) => {
  const {
    variant = 'primary',
    size = Sizes.md,
    isRounded,
    className,
    isTextFit,
  } = props;

  let classes =
    'tw-capitalize tw-h-7 tw-flex tw-items-center tw-justify-center tw-rounded tw-py-2.5 tw-px-2 tw-transition-all tw-duration-200 focus:tw-outline-none tw-tracking-wider';

  const variantClass = VARIANT_CLASSES[variant];

  const uiClasses = getUIClasses(props);

  classes = `${classes} ${variantClass} ${uiClasses}`;

  if (isTextFit) {
    classes = `${classes} tw-px-3 tw-w-max`;
  }

  classes = `${classes} ${SIZES[size]}`;

  classes = `${classes} ${size === Sizes.lg ? 'tw-text-sm' : 'tw-text-xs'}`;

  classes = `${classes} ${isRounded ? 'tw-rounded-full' : 'tw-rounded'}`;

  return `${classes} ${className || ''}`;
};

export default useButtonTheme;
