import { useMemo, useState } from 'react';
import { redirectToPage } from 'utils/nativeUtils';
import { useGlobalContext } from 'contexts';
import useStorage from 'hook/useStorage';
import { STORAGE_KEYS } from '@utils/keys';
import { useBusinessData } from 'queries/dashboard/useBusinessProfile';
import { useNsplConsent } from 'queries/nspl-consent/useNsplConsent';
import { ROUTE_NAMES } from '@utils/routes';
import { useRouter } from 'next/router';
import { getStorageItem, setStorageItem } from '@utils/storage';
import { useTreatments } from '@splitsoftware/splitio-react';
import { SplitIo } from '@utils/splitIo.enum';
import { checkForSMSPull } from '@utils/Utils';

const useLoanLaunch = () => {
  const { getItem, setItem } = useStorage();
  const { setShowProfilePopup } = useGlobalContext();
  const realmOwnerData = getItem(STORAGE_KEYS.REALM_OWNER_DATA);
  const dashboardData = getItem(STORAGE_KEYS.DASHBOARD_DATA);
  const nsplConsentStorage = getItem(STORAGE_KEYS.NSPL_CONSENT_GIVEN);
  const [showConsentPopup, setShowConsentPopup] = useState<boolean>(false);
  const router = useRouter();
  const sms_permission = getStorageItem(STORAGE_KEYS.SMS_PERMISSION);
  const smsCounter =
    Number(getStorageItem(STORAGE_KEYS.SMS_CONSENT_COUNTER)) || 0;
  const androidVersion = +(getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION) || 0);
  const { smsLimitSet } = useGlobalContext();
  const treatments = useTreatments([SplitIo.sms_home_page]);

  const [showNSPLRedirectPopup, setNSPLRedirectPopup] =
    useState<boolean>(false);
  const isConsented: boolean = useMemo(() => {
    return (
      dashboardData?.nsplConsentData?.consentStatus == false ||
      nsplConsentStorage ||
      false
    );
  }, [dashboardData, nsplConsentStorage]);

  const {
    isLoading: getBusinessProfileLoading,
    mutateAsync: getBusinessProfile,
  } = useBusinessData();

  const { isLoading: nsplCosentLoading, mutateAsync: sentNsplConsent } =
    useNsplConsent();

  const pgFetchMultipleProfile = async () => {
    const apiData = await getBusinessProfile(realmOwnerData?.userRealmId);
    if (apiData?.success) {
      if (!apiData?.data?.details?.length) {
        setShowProfilePopup(true);
        return;
      }
      const [{ profile_detail }] = apiData?.data?.details;
      if (profile_detail) {
        setNSPLRedirectPopup(true);
      } else {
        setShowProfilePopup(true);
      }
    }
  };

  const onConsentProceed = async () => {
    setShowConsentPopup(false);
    const apiData = await sentNsplConsent();
    if (apiData?.success) {
      setItem(STORAGE_KEYS.NSPL_CONSENT_GIVEN, true);
      pgFetchMultipleProfile();
    }
  };

  const nsplRedirectOk = () => {
    setNSPLRedirectPopup(false);
    setStorageItem(STORAGE_KEYS.SELECTED_FOOTER, 'Home');
    if (
      androidVersion > 187 &&
      smsLimitSet &&
      treatments[SplitIo.sms_home_page].treatment === 'on'
    ) {
      if (!sms_permission && smsCounter < 2) {
        router.push({
          pathname: ROUTE_NAMES.CONSENT_SMS,
          query: { app: 'loans_footer' },
        });
      } else if (sms_permission) {
        checkForSMSPull();
        redirectToPage(`${process.env.NEXT_PUBLIC_LOANS_URL}/loans/`, 'Loans');
      } else {
        redirectToPage(`${process.env.NEXT_PUBLIC_LOANS_URL}/loans/`, 'Loans');
      }
    } else {
      redirectToPage(`${process.env.NEXT_PUBLIC_LOANS_URL}/loans/`, 'Loans');
    }
  };

  return {
    loader: nsplCosentLoading || getBusinessProfileLoading,
    showConsentPopup,
    showNSPLRedirectPopup,
    isConsented,
    nsplRedirectOk,
    onConsentProceed,
    pgFetchMultipleProfile,
    setShowConsentPopup,
  };
};
export default useLoanLaunch;
