/* eslint-disable react/require-default-props */
import React from 'react';

interface IProps {
  height?: number;
  width?: number;
  color?: string;
}

const ProfileIconOutlined = ({
  height = 24,
  width = 24,
  color = 'black',
}: IProps) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      height="24"
      id="mask0_49_2473"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
      width="24"
      x="0"
      y="0"
    >
      <rect fill={color} height="24" width="24" />
    </mask>

    <g mask="url(#mask0_49_2467)">
      <path
        d="M12 11.6922C11.0375 11.6922 10.2135 11.3495 9.52813 10.6641C8.84271 9.97873 8.5 9.15478 8.5 8.1923C8.5 7.2298 8.84271 6.40585 9.52813 5.72045C10.2135 5.03503 11.0375 4.69232 12 4.69232C12.9625 4.69232 13.7864 5.03503 14.4718 5.72045C15.1572 6.40585 15.5 7.2298 15.5 8.1923C15.5 9.15478 15.1572 9.97873 14.4718 10.6641C13.7864 11.3495 12.9625 11.6922 12 11.6922ZM4.5 17.7884V17.0846C4.5 16.5948 4.63302 16.1413 4.89905 15.724C5.16507 15.3067 5.52051 14.9859 5.96537 14.7615C6.95384 14.2769 7.95096 13.9134 8.95672 13.6711C9.96249 13.4288 10.9769 13.3077 12 13.3077C13.023 13.3077 14.0375 13.4288 15.0432 13.6711C16.049 13.9134 17.0461 14.2769 18.0346 14.7615C18.4794 14.9859 18.8349 15.3067 19.1009 15.724C19.3669 16.1413 19.5 16.5948 19.5 17.0846V17.7884C19.5 18.2102 19.3522 18.5688 19.0567 18.8643C18.7612 19.1599 18.4025 19.3076 17.9808 19.3076H6.0192C5.59742 19.3076 5.23877 19.1599 4.94327 18.8643C4.64776 18.5688 4.5 18.2102 4.5 17.7884ZM5.99997 17.8076H18V17.0846C18 16.882 17.9413 16.6945 17.824 16.5221C17.7067 16.3496 17.5474 16.2089 17.3461 16.0999C16.4846 15.6756 15.6061 15.3541 14.7107 15.1355C13.8152 14.9169 12.9117 14.8076 12 14.8076C11.0883 14.8076 10.1847 14.9169 9.28927 15.1355C8.39384 15.3541 7.51536 15.6756 6.65382 16.0999C6.45254 16.2089 6.29325 16.3496 6.17595 16.5221C6.05863 16.6945 5.99997 16.882 5.99997 17.0846V17.8076ZM12 10.1923C12.55 10.1923 13.0208 9.99646 13.4125 9.6048C13.8041 9.21313 14 8.7423 14 8.1923C14 7.6423 13.8041 7.17146 13.4125 6.7798C13.0208 6.38813 12.55 6.1923 12 6.1923C11.45 6.1923 10.9791 6.38813 10.5875 6.7798C10.1958 7.17146 9.99997 7.6423 9.99997 8.1923C9.99997 8.7423 10.1958 9.21313 10.5875 9.6048C10.9791 9.99646 11.45 10.1923 12 10.1923Z"
        fill={color}
      />
    </g>
  </svg>
);

export default ProfileIconOutlined;
