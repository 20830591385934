import { useMutation } from 'react-query';
import { apiClient } from '@components/api/apiClient';
import paths from '@components/api/paths';
import { IRequestMeta } from 'constants/types';
import { getAuthData } from '@utils/storage';
import { CONSENT_STATUS } from 'constants/common';

export interface ConsentStatus {
  data: string;
  success: boolean;
}

export interface IResponseData extends IRequestMeta {
  data: ConsentStatus;
}

async function postNsplConsent(): Promise<IRequestMeta | undefined> {
  const { realmIdentifier, userId } = getAuthData();
  try {
    if (realmIdentifier && userId) {
      const url = paths.getConsentPostUrl(realmIdentifier, userId);
      const body = {
        input: {
          consentId: process.env.NEXT_PUBLIC_NSPL_CONSENT_ID,
          status: CONSENT_STATUS.APPROVED,
        },
      };

      const response: IRequestMeta = await apiClient.post(url, body);
      return response;
    }
  } catch (error: any) {
    return Promise.reject(error);
  }
}

const useNsplConsent = () => {
  return useMutation({
    mutationFn: postNsplConsent,
  });
};

export { useNsplConsent };
