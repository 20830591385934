import React, { memo } from 'react';
import Modal from '@components/atoms/Modal';
import { FunctionComponent, useCallback, useState } from 'react';
import { NCIcon } from '@ninjacart/nc-components-ui';
import Button from '@components/atoms/Button';
import { strings } from 'constants/translations';
import { useRouter } from 'next/router';
import { ROUTE_NAMES } from '@utils/routes';
interface ConsentPopupProps {
  isDisplayConsentPopup: boolean;
  onConsentProceed: Function;
  isCheckBoxRequired?: boolean;
  buttonLabel?: string;
  isNsplConsent?: boolean;
}

const NsplConsentPopup: FunctionComponent<ConsentPopupProps> = ({
  isDisplayConsentPopup,
  onConsentProceed,
  isCheckBoxRequired = false,
  buttonLabel = strings.procced,
  isNsplConsent = false,
}) => {
  const [isChecked, setChecked] = useState<boolean>(false);
  const router = useRouter();
  const onClickCheckBox = useCallback(() => {
    setChecked(!isChecked);
  }, [isChecked]);

  const handleTermCondition = () => {
    router.push({
      pathname: ROUTE_NAMES.NSPL_PRIVACY_POLICY,
      query: {
        from: 'TERMS',
      },
    });
  };
  const handlePrivacyPolicy = () => {
    router.push({
      pathname: ROUTE_NAMES.NSPL_PRIVACY_POLICY,
      query: {
        from: 'PRIVACY',
      },
    });
  };

  return (
    <Modal isOpen={isDisplayConsentPopup}>
      <div className="tw-flex tw-w-[50] tw-flex-col tw-items-center">
        <div className="tw-flex">
          {isCheckBoxRequired && (
            <div
              className="tw-mr-2 tw-mt-1 tw-block tw-h-5 tw-w-5"
              onClick={onClickCheckBox}
            >
              <NCIcon
                className="block"
                color={isChecked ? '#5fa744' : '#9ca3af'}
                height={20}
                iconName={isChecked ? 'square_checked' : 'square_unchecked'}
                width={20}
              />
            </div>
          )}
          <p className="tw-pl-2 tw-pr-1 tw-text-[14px]">
            {isNsplConsent ? (
              <>
                <div>
                  <span>{`${strings.understandAndAgreeText} "`}</span>
                  <span className="tw-underline" onClick={handleTermCondition}>
                    {strings.termsAndConditions}
                  </span>
                  <span>{`" ${strings.andTxt} "`}</span>
                  <span className="tw-underline" onClick={handlePrivacyPolicy}>
                    {strings.privacyPolicy}
                  </span>
                  <span>{`" ${strings.ofNspl}`}</span>
                </div>
              </>
            ) : (
              <>{strings.redirectToNspl}</>
            )}
          </p>
        </div>
        <div className="tw-mt-4 tw-flex tw-w-full tw-justify-center">
          <Button
            className="!tw-py-5 !tw-text-base"
            disabled={isCheckBoxRequired ? !isChecked : false}
            label={buttonLabel}
            onClick={() => {
              onConsentProceed();
            }}
          ></Button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(NsplConsentPopup);
