import ReactModal, { Props } from 'react-modal';

export interface ModalProps extends Props {}

function Modal(props: ModalProps) {
  const { className = '', overlayClassName = '', ...rest } = props;
  return (
    <ReactModal
      ariaHideApp={false}
      className={`tw-mx-5 tw-flex tw-rounded-xl tw-bg-white tw-p-4 tw-shadow-lg tw-outline-none ${className}`}
      overlayClassName={`tw-fixed tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-z-[100] tw-flex tw-items-center tw-justify-center tw-bg-modal-bg ${overlayClassName}`}
      shouldCloseOnOverlayClick={true}
      {...rest}
    />
  );
}

export default Modal;
