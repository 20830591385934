import LogoutIcon from 'assets/menu-icons/logout.png';
import SettingsIcon from 'assets/menu-icons/settings.png';
import { MenusType } from './types/commontypes';
import ProfileIcon from '../assets/menu-icons/my-profile.png';
import BankIcon from '../assets/menu-icons/bank-icon.png';
import NotificationIcon from '../assets/menu-icons/notifications.png';
import TeamIcon from '../assets/menu-icons/team-icon.png';
import HelpIcon from '../assets/menu-icons/help.png';
import CreditScoreIcon from '../assets/menu-icons/creditScore.png';
import LanguageIcon from '../assets/menu-icons/language.png';
import TermsIcon from '../assets/menu-icons/terms.png';
import PrivacyIcon from '../assets/menu-icons/privacy.png';
import loansinactive from '../assets/dashIcons/loansinactive.png';
import loansactive from '../assets/dashIcons/loans-active.svg';
import transactionsactive from '../assets/dashIcons/transactions-active.svg';
import transactionsinactive from '../assets/dashIcons/transactionsinactive.png';
import partiesactive from '../assets/dashIcons/parties-active.svg';
import partiesinactive from '../assets/dashIcons/partiesinactive.png';
import homeactive from '../assets/dashIcons/homeacitve.png';
import homeinactive from '../assets/dashIcons/home-inactive.svg';
import ast from 'assets/dashIcons/ast.svg';
import rewards from 'assets/dashIcons/rewards.svg';
import offer from 'assets/dashIcons/offer.svg';
import refer from 'assets/dashIcons/refer.svg';
import { ROUTE_NAMES } from './routes';
import { TraderEventType } from './eventsConstant';
import { FooterMenuName } from 'constants/types';

import { StaticImageData } from 'next/image';
import { SplitIo } from './splitIo.enum';
import { strings } from 'constants/translations';
import MandiFooterActive from 'assets/dashboardIcons/MandiFooterActiveIcon.svg';
import MandiFooter from 'assets/dashboardIcons/MandiFooterIcon.svg';
import HomeFooter from 'assets/dashboardIcons/homeFooter.svg';
import HomeFooterActive from 'assets/dashboardIcons/homeFooterActive.svg';
import NTCFooter from 'assets/dashboardIcons/NTCFooterIcon.svg';
import NTCFooterActive from 'assets/dashboardIcons/NTCFooterActive.svg';
import LoansFooter from 'assets/dashboardIcons/LoansFooter.svg';
import LoansFooterActive from 'assets/dashboardIcons/LoansFooterActive.svg';
import TranscationFooter from 'assets/dashboardIcons/TranscatioFooter.svg';
import TranscationFooterActive from 'assets/dashboardIcons/TranscationFooterActive.svg';
import Profile from 'assets/dashboardIcons/Profile.svg';
import BankAccountDetails from 'assets/dashboardIcons/BankAccountDetails.svg';
import Help from 'assets/dashboardIcons/Help.svg';
import Language from 'assets/dashboardIcons/Language.svg';
import Logout from 'assets/dashboardIcons/Logout.svg';
import ReferAndEarn from 'assets/dashboardIcons/ReferAndEarn.svg';
import Rewards from 'assets/dashboardIcons/Rewards.svg';
import SwitchBusiness from 'assets/dashboardIcons/SwitchBusinesss.svg';
import Team from 'assets/dashboardIcons/Team.svg';
import Settings from 'assets/dashboardIcons/SetttingsIcon.svg';

export const hamburgerMenu = (): MenusType[] => {
  return [
    {
      icon: ProfileIcon,
      menuName: 'My Profile',
      name: strings.My_profile,
      route: ROUTE_NAMES.PROFILE,
      trackedEventName: TraderEventType.Home_Com_Profile_Initiate,
    },
    {
      icon: BankIcon,
      menuName: 'Bank account details',
      name: strings.Bank_account_Details,
      route: ROUTE_NAMES.BANK_DETAILS,
      trackedEventName: TraderEventType.Ac_Details_Initiate,
    },
    {
      icon: NotificationIcon,
      menuName: 'Notification',
      name: strings.Notification,
      route: ROUTE_NAMES.NOTIFICAION,
      key: SplitIo.NotificationHamburger,
    },
    {
      icon: TeamIcon,
      menuName: 'Team',
      name: strings.Team,
      route: ROUTE_NAMES.TEAM,
    },
    {
      icon: HelpIcon,
      menuName: 'Help',
      name: strings.Help,
      route: ROUTE_NAMES.HELP,
      trackedEventName: TraderEventType.Menu_help_initiate,
    },
    {
      icon: TermsIcon,
      menuName: 'Terms & Conditions',
      name: strings['Terms_&_condition'],
      route: `${ROUTE_NAMES.TERMSCONDITION}?ref=${ROUTE_NAMES.DASHBOARD}`,
      trackedEventName: TraderEventType.Terms_initiate,
    },
    {
      icon: PrivacyIcon,
      menuName: 'Privacy Policy',
      name: strings.Privacy_Policy,
      route: `${ROUTE_NAMES.PRIVACY_POLICY}?ref=${ROUTE_NAMES.DASHBOARD}`,
      trackedEventName: TraderEventType.Privacy_policy_initiate,
    },
    {
      icon: SettingsIcon,
      menuName: 'Settings',
      name: strings.settings,
      route: ROUTE_NAMES.SETTINGS,
      key: SplitIo.SoundboxSettings,
    },
    {
      icon: LanguageIcon,
      menuName: 'Language',
      name: strings.languageTxt,
      route: ROUTE_NAMES.LANGUAGE,
      trackedEventName: TraderEventType.Menu_language_initiate,
    },
    {
      icon: LogoutIcon,
      menuName: 'Logout',
      name: strings.Logout,
      route: ROUTE_NAMES.LOGOUT,
      trackedEventName: TraderEventType.Menu_logout_initiate,
    },
  ];
};

export const hamburgerStaffMenu = (): MenusType[] => {
  return [
    {
      icon: ProfileIcon,
      menuName: 'My Profile',
      name: strings.My_profile,
      route: ROUTE_NAMES.PROFILE,
      trackedEventName: TraderEventType.Home_Com_Profile_Initiate,
    },
    {
      icon: HelpIcon,
      menuName: 'Help',
      name: strings.Help,
      route: ROUTE_NAMES.HELP,
      trackedEventName: TraderEventType.Menu_help_initiate,
    },
    {
      icon: LanguageIcon,
      menuName: 'Language',
      name: strings.languageTxt,
      route: ROUTE_NAMES.LANGUAGE,
      trackedEventName: TraderEventType.Menu_language_initiate,
    },
    {
      icon: TermsIcon,
      menuName: 'Terms & Conditions',
      name: strings['Terms_&_condition'],
      route: `${ROUTE_NAMES.TERMSCONDITION}?ref=${ROUTE_NAMES.DASHBOARD}`,
      trackedEventName: TraderEventType.Terms_initiate,
    },
    {
      icon: PrivacyIcon,
      menuName: 'Privacy Policy',
      name: strings.Privacy_Policy,
      route: `${ROUTE_NAMES.PRIVACY_POLICY}?ref=${ROUTE_NAMES.DASHBOARD}`,
      trackedEventName: TraderEventType.Privacy_policy_initiate,
    },
    {
      icon: TeamIcon,
      menuName: 'Switch Business',
      name: strings.SwitchBusiness,
      route: ROUTE_NAMES.SWITCH_BUSINESS,
    },
    {
      icon: LogoutIcon,
      menuName: 'Logout',
      name: strings.Logout,
      route: ROUTE_NAMES.LOGOUT,
      trackedEventName: TraderEventType.Menu_logout_initiate,
    },
  ];
};

export const hamburgerMiscellaneousMenu = (): MenusType[] => {
  return [
    {
      menuName: 'Add Selling Trade',
      name: strings.AddsellingTrade,
      icon: ast,
      route: 'app/content/select-party-new',
      key: SplitIo.AddSellingTrade,
      trackedEventName: 'Add_selling_trade_initiate',
    },
    {
      menuName: 'Rewards',
      name: strings.rewards,
      icon: rewards,
      route: 'app/content/rewards',
      key: SplitIo.Rewards,
      trackedEventName: 'Rewards_initiate',
    },
    {
      menuName: 'Refer & Earn',
      name: strings.referAndEarn,
      icon: refer,
      route: 'app/content/refer',
      key: SplitIo.ReferAndEarn,
      trackedEventName: 'Refer_initiate',
    },
    {
      menuName: 'Offers',
      name: strings.offers,
      icon: offer,
      route: 'app/content/upcoming-offers',
      key: SplitIo.Offers,
      trackedEventName: 'Offers_initiate',
    },
    {
      icon: CreditScoreIcon,
      name: strings.CheckYourCreditScore,
      menuName: 'Check Your Credit Score',
      key: SplitIo.Check_Credit_Score,
      route: ROUTE_NAMES.CREDIT_SCODE,
    },
    {
      icon: TeamIcon,
      menuName: 'Switch Business',
      name: strings.SwitchBusiness,
      route: ROUTE_NAMES.SWITCH_BUSINESS,
    },
  ];
};

export interface IFooter {
  menuName: FooterMenuName;
  name: string;
  url: string;
  icon: StaticImageData;
  activeIcon: StaticImageData;
  id: number;
  staffAccess: boolean;
  margin?: boolean;
  key: any;
}

export const footer = (): IFooter[] => {
  return [
    {
      menuName: 'Home',
      name: strings.Home,
      icon: homeinactive,
      activeIcon: homeactive,
      id: 1,
      margin: true,
      url: '',
      staffAccess: true,
      key: SplitIo.HomeIconFooter,
    },
    {
      menuName: 'Parties',
      name: strings.Parties,
      icon: partiesinactive,
      activeIcon: partiesactive,
      id: 2,
      margin: true,
      url: 'app/content/select-party',
      staffAccess: false,
      key: SplitIo.PartiesHpFooter,
    },
    {
      menuName: 'Loans',
      name: strings.Loans,
      icon: loansinactive,
      activeIcon: loansactive,
      id: 4,
      url: 'loans',
      staffAccess: false,
      key: SplitIo.LoansHpFooter,
    },
    {
      menuName: 'Transactions',
      name: strings.Transactions,
      icon: transactionsinactive,
      activeIcon: transactionsactive,
      id: 5,
      url: 'app/content/trans-history',
      staffAccess: true,
      key: SplitIo.TransactionHpFooter,
    },
  ];
};

export const footerNew = (): IFooter[] => {
  return [
    {
      menuName: 'Home',
      name: strings.Home,
      icon: HomeFooter,
      activeIcon: HomeFooterActive,
      id: 1,
      margin: true,
      url: '',
      staffAccess: true,
      key: SplitIo.HomeIconFooter,
    },
    {
      menuName: 'Mandi News',
      name: strings.Mandi_News,
      icon: MandiFooter,
      activeIcon: MandiFooterActive,
      id: 2,
      url: '',
      staffAccess: false,
      key: SplitIo.Mandi_News,
    },
    {
      menuName: 'NTC',
      name: 'NTC',
      icon: NTCFooter,
      activeIcon: NTCFooterActive,
      id: 3,
      url: '',
      staffAccess: false,
      key: SplitIo.view_ninja_one,
    },
    {
      menuName: 'Transactions',
      name: strings.Transactions,
      icon: TranscationFooter,
      activeIcon: TranscationFooterActive,
      id: 4,
      url: 'app/content/trans-history',
      staffAccess: true,
      key: SplitIo.TransactionHpFooter,
    },
    {
      menuName: 'Loans',
      name: strings.Loans,
      icon: LoansFooter,
      activeIcon: LoansFooterActive,
      id: 5,
      url: 'loans',
      staffAccess: false,
      key: SplitIo.LoansHpFooter,
    },
  ];
};

export const hamburgerMenuNew = (): MenusType[] => {
  return [
    {
      icon: Profile,
      menuName: 'My Profile',
      name: strings.My_profile,
      route: ROUTE_NAMES.PROFILE,
      trackedEventName: TraderEventType.Home_Com_Profile_Initiate,
    },
    {
      icon: BankAccountDetails,
      menuName: 'Bank account details',
      name: strings.Bank_account_Details,
      route: ROUTE_NAMES.BANK_DETAILS,
      trackedEventName: TraderEventType.Ac_Details_Initiate,
    },
    {
      icon: Team,
      menuName: 'Team',
      name: strings.Team,
      route: ROUTE_NAMES.TEAM,
    },
    {
      icon: Help,
      menuName: 'Help',
      name: strings.Help,
      route: ROUTE_NAMES.HELP,
      trackedEventName: TraderEventType.Menu_help_initiate,
    },
    {
      icon: Settings,
      menuName: 'Settings',
      name: strings.settings,
      route: ROUTE_NAMES.SETTINGS,
      key: SplitIo.SoundboxSettings,
    },
    {
      icon: Language,
      menuName: 'Language',
      name: strings.languageTxt,
      route: ROUTE_NAMES.LANGUAGE,
      trackedEventName: TraderEventType.Menu_language_initiate,
    },
    {
      icon: SwitchBusiness,
      menuName: 'Switch Business',
      name: strings.SwitchBusiness,
      route: ROUTE_NAMES.SWITCH_BUSINESS,
    },
    {
      menuName: 'Rewards',
      name: strings.rewards,
      icon: Rewards,
      route: 'app/content/rewards',
      key: SplitIo.Rewards,
      trackedEventName: 'Rewards_initiate',
    },
    {
      menuName: 'Refer & Earn',
      name: strings.referAndEarn,
      icon: ReferAndEarn,
      route: 'app/content/refer',
      key: SplitIo.ReferAndEarn,
      trackedEventName: 'Refer_initiate',
    },
    {
      icon: Logout,
      menuName: 'Logout',
      name: strings.Logout,
      route: ROUTE_NAMES.LOGOUT,
      trackedEventName: TraderEventType.Menu_logout_initiate,
    },
  ];
};

export const hamburgerStaffMenuNew = (): MenusType[] => {
  return [
    {
      icon: Profile,
      menuName: 'My Profile',
      name: strings.My_profile,
      route: ROUTE_NAMES.PROFILE,
      trackedEventName: TraderEventType.Home_Com_Profile_Initiate,
    },
    {
      icon: Help,
      menuName: 'Help',
      name: strings.Help,
      route: ROUTE_NAMES.HELP,
      trackedEventName: TraderEventType.Menu_help_initiate,
    },
    {
      icon: Language,
      menuName: 'Language',
      name: strings.languageTxt,
      route: ROUTE_NAMES.LANGUAGE,
      trackedEventName: TraderEventType.Menu_language_initiate,
    },
    {
      icon: Team,
      menuName: 'Switch Business',
      name: strings.SwitchBusiness,
      route: ROUTE_NAMES.SWITCH_BUSINESS,
    },
    {
      icon: Logout,
      menuName: 'Logout',
      name: strings.Logout,
      route: ROUTE_NAMES.LOGOUT,
      trackedEventName: TraderEventType.Menu_logout_initiate,
    },
  ];
};
