import React, { useState } from 'react';
import HomeIcon from '@components/atoms/Icons/HomeIcon';
import MenuIcon from '@components/atoms/Icons/MenuIcon';
import ProfileIconOutlined from '@components/atoms/Icons/ProfileIconOutlined';
import { redirectToPage } from '@utils/nativeUtils';
import useEvents from 'hook/useEvents';
import { TraderEventType } from '@utils/eventsConstant';
import { getStorageItem } from '@utils/storage';
import { STORAGE_KEYS } from '@utils/keys';
import { MANDI_NEWS_NEW_URL_VERSION } from 'constants/common';

const MandiNewsFooter = () => {
  const [activeIcon] = useState<'HOME' | 'OFFERINGS' | 'PROFILE'>('OFFERINGS');
  const { triggerEvent } = useEvents();
  const appVersion = getStorageItem(STORAGE_KEYS.APP_BUILD_VERSION) || 0;
  const url =
    +appVersion >= MANDI_NEWS_NEW_URL_VERSION
      ? process.env.NEXT_PUBLIC_MANDI_NEWS
      : process.env.NEXT_PUBLIC_MANDI_NEWS_OLD;

  const handleHomeClick = () => {
    triggerEvent(TraderEventType.Generic_event, {
      eventName: 'MN_NavBar_Home_Clicked',
    });
    redirectToPage(url as string, 'MandiNews', '');
    return;
  };

  const handleOfferingsClick = () => {
    triggerEvent(TraderEventType.Generic_event, {
      eventName: 'MN_NavBar_Offerings_Clicked',
    });
  };

  const handleProfileClick = () => {
    triggerEvent(TraderEventType.Generic_event, {
      eventName: 'MN_NavBar_ProfileIcon_Clicked',
    });
    redirectToPage(`${url}profile`, 'MandiNews', '');
  };

  return (
    <section className="tw-fixed tw-bottom-0 tw-z-10 tw-flex tw-max-h-[72px] tw-w-full tw-justify-around tw-bg-white tw-p-2 tw-px-0 tw-shadow-[0px_-8px_10px_0px_#1A065920]">
      <div
        className="tw-flex tw-flex-col tw-items-center tw-gap-0.5"
        onClick={handleHomeClick}
      >
        <HomeIcon
          color={activeIcon === 'HOME' ? '#5FA744' : '#909196'}
          height={28}
          width={28}
        />
        <span
          className={`tw-text-xs ${
            activeIcon === 'HOME'
              ? 'tw-font-medium tw-text-primary-500'
              : 'tw-text-[#909196]'
          }`}
        >
          Home
        </span>
        {activeIcon === 'HOME' && (
          <span className="tw-h-1 tw-w-2 tw-rounded-lg tw-bg-primary-500">
            {' '}
          </span>
        )}
      </div>

      <div
        className="tw-flex tw-flex-col tw-items-center tw-gap-0.5"
        onClick={handleOfferingsClick}
      >
        <MenuIcon
          color={activeIcon === 'OFFERINGS' ? '#5FA744' : '#909196'}
          height={28}
          width={28}
        />
        <span
          className={`tw-text-xs ${
            activeIcon === 'OFFERINGS'
              ? 'tw-font-medium tw-text-primary-500'
              : 'tw-text-[#909196]'
          }`}
        >
          Offerings
        </span>
        {activeIcon === 'OFFERINGS' && (
          <span className="tw-h-1 tw-w-2 tw-rounded-lg tw-bg-primary-500">
            {' '}
          </span>
        )}
      </div>

      <div
        className="tw-flex tw-flex-col tw-items-center tw-gap-0.5"
        onClick={handleProfileClick}
      >
        <ProfileIconOutlined
          color={activeIcon === 'PROFILE' ? '#5FA744' : '#909196'}
          height={28}
          width={28}
        />
        <span
          className={`tw-text-xs ${
            activeIcon === 'PROFILE'
              ? 'tw-font-medium tw-text-primary-500'
              : 'tw-text-[#909196]'
          }`}
        >
          Profile
        </span>
        {activeIcon === 'PROFILE' && (
          <span className="tw-h-1 tw-w-2 tw-rounded-lg tw-bg-primary-500">
            {' '}
          </span>
        )}
      </div>
    </section>
  );
};

export default MandiNewsFooter;
